import { invertObjKeyValue } from "./utils";

const frenchToYDSObject = {
  "5a": "5.8",
  "5a+": "5.8",
  "5b": "5.9",
  "5b+": "5.9",
  "5c": "5.10a",
  "5c+": "5.10a",
  "6a": "5.10b",
  "6a+": "5.10c",
  "6b": "5.10d",
  "6b+": "5.11a",
  "6c": "5.11b",
  "6c+": "5.11c",
  "7a": "5.11d",
  "7a+": "5.12a",
  "7b": "5.12b",
  "7b+": "5.12c",
  "7c": "5.12d",
  "7c+": "5.13a",
  "8a": "5.13b",
  "8a+": "5.13c",
  "8b": "5.13d",
  "8b+": "5.14a",
  "8c": "5.14b",
  "8c+": "5.14c",
  "9a": "5.14d",
  "9a+": "5.15a",
  "9b": "5.15b",
  "9b+": "5.15c",
  "9c": "5.15d",
  "9c+": "5.16a",
};

export type FrenchGrade = keyof typeof frenchToYDSObject;
export type YDSGrade = typeof frenchToYDSObject[keyof typeof frenchToYDSObject];

export const frenchGrades = Object.keys(frenchToYDSObject);
export const yDSGrades = Object.values(frenchToYDSObject);

export const frenchToYDS = (frenchGrade: FrenchGrade) => frenchToYDSObject[frenchGrade];
export const yDSToFrench = (yDSGrade: YDSGrade) => invertObjKeyValue(frenchToYDSObject)[yDSGrade];
