import SendsTable from "./SendsTable";
import { SportSend } from "../../API";

type PersonSendsTableProps = {
  tableProps?: any;
  sends: SportSend[];
};

const PersonSendsTable = ({ tableProps, sends }: PersonSendsTableProps) => {
  const columnNames = ["Name", "FA", "Date"];
  return <SendsTable tableProps={tableProps} sends={sends} columnNames={columnNames}></SendsTable>;
};

export default PersonSendsTable;
