import { AutoComplete, Button, DatePicker, Form, FormInstance, Input, Select } from "antd";
import { useFetchPeople, useFetchSportRoutes } from "../../hooks/useFetchAPI";

import { enumToValuesList } from "../utils/utils";
import { postSportSend } from "../../api/post";
import { useEffect } from "react";

// TODO: store elsewhere as an enum
const sendTypes = ["redpoint", "flash", "onsight"];
const normalizeString = (s: string) =>
  s
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export enum BoolToString {
  no = 0, // eslint-disable-line
  yes = 1, // eslint-disable-line
}

const resetForm = (form: FormInstance) => {
  form.resetFields();
  form.setFieldsValue({
    fa: BoolToString.no,
    sendType: sendTypes[0],
  });
};

const AddSportSendForm = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    resetForm(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const yesNoList = enumToValuesList(BoolToString);

  const {
    isLoading: peopleIsLoading,
    isError: peopleIsError,
    data: peopleData,
    error: peopleError,
  } = useFetchPeople();
  const {
    isLoading: sportRoutesIsLoading,
    isError: sportRoutesIsError,
    data: sportRoutesData,
    error: sportRoutesError,
  } = useFetchSportRoutes();

  if (peopleIsLoading || sportRoutesIsLoading) {
    return <div>loading...</div>;
  }

  if (peopleIsError || sportRoutesIsError) {
    console.log(peopleError);
    console.log(sportRoutesError);
    return <div>An unknown error occured</div>;
  }

  const onFinish = () => {
    console.log("onFinish", form.getFieldsValue());
    postSportSend({
      personID: peopleData!.find(
        (p) => `${p.firstname} ${p.lastname}` === form.getFieldValue("person")
      )!.id,
      routeID: sportRoutesData!.find((r) => r.name === form.getFieldValue("route"))!.id,
      date: form.getFieldValue("sendDate").format("YYYY-MM-DD"),
      type: form.getFieldValue("sendType"),
      fa: form.getFieldValue("fa") === BoolToString.yes,
      description: form.getFieldValue("description"),
    }).then(() => {
      resetForm(form);
    });
  };

  const onReset = () => {
    resetForm(form);
  };

  const onFinishFailed = () => {
    console.log("onFinishFailed");
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          label="Athlete name"
          name="person"
          rules={[
            {
              required: true,
              type: "enum",
              enum: peopleData!.map((p) => `${p.firstname} ${p.lastname}`),
              message: "Unknown athlete",
            },
          ]}
        >
          <AutoComplete
            style={{ width: 300 }}
            options={peopleData!.map((p) => ({ value: `${p.firstname} ${p.lastname}` }))}
            placeholder="Athlete name"
            filterOption={(inputValue, option) =>
              normalizeString(option!.value).indexOf(normalizeString(inputValue)) !== -1
            }
          />
        </Form.Item>
        <Form.Item
          label="Route name"
          name="route"
          rules={[
            {
              required: true,
              type: "enum",
              enum: sportRoutesData!.map((r) => r.name),
              message: "Unknown route",
            },
          ]}
        >
          <AutoComplete
            style={{ width: 300 }}
            options={sportRoutesData!.map((r) => ({ value: `${r.name}` }))}
            placeholder="Sport route"
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item
          label="Date"
          name="sendDate"
          rules={[
            {
              required: true,
              message: "Send date is required",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Send type"
          name="sendType"
          rules={[
            {
              required: true,
              message: "Send type is required",
            },
          ]}
        >
          <Select placeholder="Send type" style={{ width: 100 }}>
            {sendTypes.map((s) => (
              <Select.Option key={s} value={s}>
                {s}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="First Ascent"
          name="fa"
          rules={[
            {
              required: true,
              message: "First ascent is required",
            },
          ]}
        >
          <Select style={{ width: 100 }}>
            {yesNoList.map((s) => (
              <Select.Option key={s} value={s}>
                {BoolToString[s]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <span style={{ paddingRight: 10 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </span>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSportSendForm;
