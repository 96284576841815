import { CreatePersonInput, CreateSportRouteInput, CreateSportSendInput } from "../API";
import { Person, SportRoute, SportSend } from "../API";
import { createPerson, createSportRoute, createSportSend } from "../graphql/mutations";

import { API } from "aws-amplify";
import { notification } from "antd";

function createPost<Input, Output>(createQuery: string) {
  return async (input: Input) => {
    console.log("uploading...", input);
    const apiGet = API.graphql({
      query: createQuery,
      variables: { input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }) as Promise<Output>;
    return apiGet
      .then((data: any) => {
        console.log(data);
        const dataKeys = Object.keys(data.data);
        if (dataKeys.length === 1) {
          const id = data.data[dataKeys[0]].id;
          notification.success({
            message: "Data successfully added",
            description: `The data has beed added on the database. id = ${id}`,
          });
        }
        return data;
      })
      .catch(() => {
        notification.error({
          message: "Request failed",
          description:
            "An error occurred during your request. " +
            "It might be because you don't have enough rights.",
        });
      });
  };
}

export const postSportSend = createPost<CreateSportSendInput, SportSend>(createSportSend);

export const postPerson = createPost<CreatePersonInput, Person>(createPerson);

export const postSportRoute = createPost<CreateSportRouteInput, SportRoute>(createSportRoute);
