import { ColumnsType } from "antd/lib/table/interface";
import Emoji from "../utils/Emoji";
import { Link } from "react-router-dom";
import SportGrade from "../utils/SportGrade";
import { SportSend } from "../../API";
import { Table } from "antd";

type SendsTableProps = {
  tableProps?: any;
  sends: SportSend[];
  columnNames: string[];
};

const SendsTable = ({ tableProps, sends, columnNames }: SendsTableProps) => {
  let columns: ColumnsType<SportSend> = [
    {
      title: "Name",
      dataIndex: "person",
      sorter: (a, b) => a.person.firstname.localeCompare(b.person.firstname),
      render: (person) => (
        <Link to={`/athletes/${person.id}`}>{`${person.firstname} ${person.lastname}`}</Link>
      ),
    },
    {
      title: "Route",
      dataIndex: "route",
      sorter: (a, b) => a.route.name.localeCompare(b.route.name),
      render: (route) => <Link to={`/routes/${route.id}`}>{route.name}</Link>,
    },
    {
      title: "Grade",
      dataIndex: ["route", "grade"],
      sorter: (a, b) => a.route.grade.localeCompare(b.route.grade),
      render: (grade) => <SportGrade frenchGrade={grade} />,
    },
    {
      title: "FA",
      dataIndex: "fa",
      sorter: (a, b) => +b.fa - +a.fa,
      render: (fa) => fa && <Emoji symbol="✔️" />,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (date) => new Date(date).toISOString().slice(0, 10),
    },
    {
      title: "Link",
      dataIndex: "id",
      render: (id) => <Link to={`/sends/${id}`}>Link</Link>,
    },
  ];

  columns = columnNames.map((name) => columns.find((col) => col.title === name)!);

  const rows = sends
    .map((send) => {
      return {
        key: send.id,
        ...send,
      };
    })
    .sort((row1, row2) => row2.date.localeCompare(row1.date));

  return <Table {...tableProps} columns={columns} dataSource={rows} />;
};

export default SendsTable;
