import { Select } from "antd";
import { enumToKeysList } from "../utils/utils";

const { Option } = Select;

const createGradeSelect = ({ gradeTypeEnum, selectedGradeType, setGradeType }: any) => {
  return (
    <Select value={gradeTypeEnum[selectedGradeType]} onChange={setGradeType}>
      {enumToKeysList(gradeTypeEnum).map((gradeType) => (
        <Option
          key={gradeType}
          // onClick={() => setGradeType(gradeTypeEnum[gradeType])}
          value={gradeTypeEnum[gradeType]}
        >
          {gradeType}
        </Option>
      ))}
    </Select>
  );
};

export default createGradeSelect;
