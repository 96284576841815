import SendsTable from "./SendsTable";
import { SportSend } from "../../API";

type RouteSendsTableProps = {
  tableProps?: any;
  sends: SportSend[];
};

const RouteSendsTable = ({ tableProps, sends }: RouteSendsTableProps) => {
  const columnNames = ["Route", "Grade", "FA", "Date"];
  return <SendsTable tableProps={tableProps} sends={sends} columnNames={columnNames}></SendsTable>;
};

export default RouteSendsTable;
