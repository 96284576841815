/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPerson = /* GraphQL */ `
  mutation CreatePerson($input: CreatePersonInput!, $condition: ModelPersonConditionInput) {
    createPerson(input: $input, condition: $condition) {
      id
      countryCode
      firstname
      lastname
      birthDate
      description
      sends {
        items {
          id
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson($input: UpdatePersonInput!, $condition: ModelPersonConditionInput) {
    updatePerson(input: $input, condition: $condition) {
      id
      countryCode
      firstname
      lastname
      birthDate
      description
      sends {
        items {
          id
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson($input: DeletePersonInput!, $condition: ModelPersonConditionInput) {
    deletePerson(input: $input, condition: $condition) {
      id
      countryCode
      firstname
      lastname
      birthDate
      description
      sends {
        items {
          id
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSportRoute = /* GraphQL */ `
  mutation CreateSportRoute(
    $input: CreateSportRouteInput!
    $condition: ModelSportRouteConditionInput
  ) {
    createSportRoute(input: $input, condition: $condition) {
      id
      name
      grade
      description
      sends {
        items {
          id
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSportRoute = /* GraphQL */ `
  mutation UpdateSportRoute(
    $input: UpdateSportRouteInput!
    $condition: ModelSportRouteConditionInput
  ) {
    updateSportRoute(input: $input, condition: $condition) {
      id
      name
      grade
      description
      sends {
        items {
          id
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSportRoute = /* GraphQL */ `
  mutation DeleteSportRoute(
    $input: DeleteSportRouteInput!
    $condition: ModelSportRouteConditionInput
  ) {
    deleteSportRoute(input: $input, condition: $condition) {
      id
      name
      grade
      description
      sends {
        items {
          id
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSportSend = /* GraphQL */ `
  mutation CreateSportSend(
    $input: CreateSportSendInput!
    $condition: ModelSportSendConditionInput
  ) {
    createSportSend(input: $input, condition: $condition) {
      id
      routeID
      personID
      type
      date
      fa
      description
      route {
        id
        name
        grade
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      person {
        id
        countryCode
        firstname
        lastname
        birthDate
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSportSend = /* GraphQL */ `
  mutation UpdateSportSend(
    $input: UpdateSportSendInput!
    $condition: ModelSportSendConditionInput
  ) {
    updateSportSend(input: $input, condition: $condition) {
      id
      routeID
      personID
      type
      date
      fa
      description
      route {
        id
        name
        grade
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      person {
        id
        countryCode
        firstname
        lastname
        birthDate
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSportSend = /* GraphQL */ `
  mutation DeleteSportSend(
    $input: DeleteSportSendInput!
    $condition: ModelSportSendConditionInput
  ) {
    deleteSportSend(input: $input, condition: $condition) {
      id
      routeID
      personID
      type
      date
      fa
      description
      route {
        id
        name
        grade
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      person {
        id
        countryCode
        firstname
        lastname
        birthDate
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
