import Emoji from "../utils/Emoji";
import { Person } from "../../API";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { personToFullname } from "../../models/utils";

export interface PersonProp {
  person: Person;
}

const FullnamePerson = ({ person }: PersonProp) => (
  <>
    <Emoji symbol={getUnicodeFlagIcon(person.countryCode)} />
    <span>{personToFullname(person)}</span>
  </>
);
export default FullnamePerson;
