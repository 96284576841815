import { createContext, useContext } from "react";

import { useLocalStorage } from "usehooks-ts";

export const createGradeContext = (gradeTypeEnum: any) =>
  createContext({
    selectedGradeType: 0,
    gradeTypeEnum: gradeTypeEnum,
    setGradeType: (g: any) => {
      console.error("Unexpected situation, the provider was certainly forgotten...");
    },
  });

const CreateGradeProvider = ({ children, gradeContext, localStorageKey }: any) => {
  // TODO: use default depending on the country accessing the website
  const [selectedGradeType, setGradeType] = useLocalStorage(localStorageKey, 0);
  const { gradeTypeEnum } = useContext(gradeContext);

  return (
    <gradeContext.Provider
      value={{
        selectedGradeType,
        gradeTypeEnum,
        setGradeType,
      }}
    >
      {children}
    </gradeContext.Provider>
  );
};

export default CreateGradeProvider;
