import { FrenchGrade, frenchToYDS } from "./grades";
import { SportGradeContext, SportGradeSystems } from "../../providers/SportGradeProvider";

import { useContext } from "react";

type SportGradeProps = {
  frenchGrade: FrenchGrade;
};

const SportGrade = ({ frenchGrade }: SportGradeProps) => {
  const { selectedGradeType: selectedSportGradeType } = useContext(SportGradeContext);

  let sportGradeString = "";

  switch (selectedSportGradeType) {
    case SportGradeSystems.French:
      sportGradeString = frenchGrade;
      break;
    case SportGradeSystems.YDS:
      sportGradeString = frenchToYDS(frenchGrade);
      break;
  }

  return <span>{sportGradeString}</span>;
};

export default SportGrade;
