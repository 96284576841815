import { AutoComplete, Button, Form, FormInstance, Input } from "antd";

import { frenchGrades } from "../utils/grades";
import { postSportRoute } from "../../api/post";
import { useEffect } from "react";

const resetForm = (form: FormInstance) => {
  form.resetFields();
};

const AddSportRouteForm = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    resetForm(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    console.log("onFinish", form.getFieldsValue());
    postSportRoute({
      name: form.getFieldValue("name"),
      grade: form.getFieldValue("grade"),
      description: form.getFieldValue("description"),
    }).then(() => {
      resetForm(form);
    });
  };

  const onReset = () => {
    resetForm(form);
  };

  const onFinishFailed = () => {
    console.log("onFinishFailed");
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Grade"
          name="grade"
          rules={[
            {
              required: true,
              type: "enum",
              enum: frenchGrades,
              message: "Unknown grade",
            },
          ]}
        >
          <AutoComplete
            options={frenchGrades.map((g) => ({ value: g }))}
            placeholder="choose grade"
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <span style={{ paddingRight: 10 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </span>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSportRouteForm;
