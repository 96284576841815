export const enumToKeysList = (enumme: any): string[] => {
  return Object.keys(enumme)
    .filter((value: any) => !isNaN(Number(value)))
    .map((key) => enumme[key]);
};

export const enumToValuesList = (enumme: any): number[] => {
  return Object.keys(enumme)
    .filter((value: any) => isNaN(Number(value)))
    .map((key) => enumme[key]);
};

export const centeredMenuStyle = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
} as const;

export const invertObjKeyValue = (obj: any) =>
  Object.keys(obj).reduce((ret: any, key) => {
    ret[obj[key]] = key;
    return ret;
  }, {});

export const computeAge = (birthDate: Date) => {
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
