import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { QueryClient, QueryClientProvider } from "react-query";

import { Amplify } from "aws-amplify";
import InfoPageMarkdown from "./pages/InfoPage.md";
import Router from "./router";
import SportGradeProvider from "./providers/SportGradeProvider";
import awsExports from "./aws-exports";
import { useLocalStorage } from "usehooks-ts";

const queryClient = new QueryClient();
Amplify.configure(awsExports);

function App() {
  const [, setInfoPageMarkdown] = useLocalStorage("info-page-markdown", "");

  fetch(InfoPageMarkdown)
    .then((res) => res.text())
    .then((text) => setInfoPageMarkdown(text));

  return (
    <QueryClientProvider client={queryClient}>
      <SportGradeProvider>
        <Router />
      </SportGradeProvider>
    </QueryClientProvider>
  );
}

export default App;
