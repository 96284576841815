import PersonSendsTable from "../components/tables/PersonSendsTable";
import RouteWithGrade from "../components/models/RouteWithGrade";
import { useFetchOneSportRoute } from "../hooks/useFetchAPI";
import { useParams } from "react-router-dom";

const RoutePage = () => {
  let { id } = useParams();

  const { data: sportRoute, error, isError, isLoading } = useFetchOneSportRoute(id!);

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>An unknown error occured during fetching.</div>;
  }

  if (!sportRoute) {
    return <div>Unknown route.</div>;
  }

  return (
    <div>
      <h1>Route</h1>
      <h2>
        <RouteWithGrade route={sportRoute} />
      </h2>
      {sportRoute.description && (
        <>
          <h3>Description</h3>
          {sportRoute.description}
        </>
      )}
      <h3>List of all sends</h3>
      <PersonSendsTable sends={sportRoute.sends!.items}></PersonSendsTable>
    </div>
  );
};

export default RoutePage;
