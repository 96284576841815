import { FrenchGrade } from "../utils/grades";
import SportGrade from "../utils/SportGrade";
import { SportRoute } from "../../API";

export interface RouteProp {
  route: SportRoute;
}

const RouteWithGrade = ({ route }: RouteProp) => (
  <>
    {route.name} <SportGrade frenchGrade={route.grade as FrenchGrade} />
  </>
);
export default RouteWithGrade;
