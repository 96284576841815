import FullnamePerson from "../components/models/FullnamePerson";
import RouteWithGrade from "../components/models/RouteWithGrade";
import { useFetchOneSportSend } from "../hooks/useFetchAPI";
import { useParams } from "react-router-dom";

const SendPage = () => {
  let { id } = useParams();

  const { data: sportSend, error, isError, isLoading } = useFetchOneSportSend(id!);

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>An unknown error occured during fetching.</div>;
  }

  if (!sportSend) {
    return <div>Unknown route.</div>;
  }
  console.log(sportSend);

  return (
    <div>
      <h1>Send</h1>
      <h3>Athlete</h3>
      <p>
        <FullnamePerson person={sportSend.person} />
      </p>
      <h3>Route</h3>
      <p>
        <RouteWithGrade route={sportSend.route} />
      </p>
      {sportSend.description && (
        <>
          <h3>Description</h3>
          {sportSend.description}
        </>
      )}
    </div>
  );
};

export default SendPage;
