import { SportGradeContext } from "../../providers/SportGradeProvider";
import createGradeSelect from "./GradeSelect";
import { useContext } from "react";

const SportGradeSelect = () => {
  const { gradeTypeEnum, selectedGradeType, setGradeType } = useContext(SportGradeContext);

  return createGradeSelect({
    gradeTypeEnum: gradeTypeEnum,
    selectedGradeType: selectedGradeType,
    setGradeType: setGradeType,
  });
};

export default SportGradeSelect;
