import "@aws-amplify/ui-react/styles.css";

import AddAthleteForm from "../components/forms/AddAthleteForm";
import AddSportRouteForm from "../components/forms/AddSportRouteForm";
import AddSportSendForm from "../components/forms/AddSportSendForm";
import { Button } from "antd";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";

const AdminPage = () => {
  const { user, signOut } = useAuthenticator();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate("/");
  };

  return (
    <div>
      <h2>Admin Page</h2>
      <div>
        <h3>Hello {user.username}</h3>
        <Button onClick={handleSignOut}>Sign out</Button>
      </div>
      <h3>Add Sport Send</h3>
      <AddSportSendForm />
      <h3>Add Athlete</h3>
      <AddAthleteForm />
      <h3>Add Sport Route</h3>
      <AddSportRouteForm />
    </div>
  );
};

export default withAuthenticator(AdminPage);
