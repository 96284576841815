import { Button, Grid, Table } from "antd";
import { Person, SportSend } from "../../API";
import { useEffect, useState } from "react";

import { ColumnsType } from "antd/lib/table/interface";
import Emoji from "../utils/Emoji";
import { Link } from "react-router-dom";
import RouteSendsTable from "./RouteSendsTable";
import SportGrade from "../utils/SportGrade";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { groupBy } from "lodash";
import { personToFullname } from "../../models/utils";

const { useBreakpoint } = Grid;

type SportCounterTableProps = {
  sportSends: SportSend[];
};

interface CounterRow {
  key: string;
  rank: number;
  athlete: Person;
  hardestGrade: string;
  score: number;
  sends: SportSend[];
}

const SportCounterTable = ({ sportSends }: SportCounterTableProps) => {
  const screens = useBreakpoint();

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  // this way, when we change the sportsends (e.g. go from 2020 to 2021)
  // it will reset the expanded routes sent
  useEffect(() => {
    setExpandedKeys([]);
  }, [sportSends]);

  const columns: ColumnsType<CounterRow> = [
    {
      title: "Rank",
      dataIndex: "rank",
      render: (rank) => (
        <span>
          {rank}
          {rank === 1 && <Emoji symbol="🥇" />}
          {rank === 2 && <Emoji symbol="🥈" />}
          {rank === 3 && <Emoji symbol="🥉" />}
        </span>
      ),
      sorter: (a, b) => a.rank - b.rank,
    },
    {
      title: "Athlete",
      dataIndex: "athlete",
      sorter: (a, b) => personToFullname(a.athlete).localeCompare(personToFullname(b.athlete)),
      render: (athlete) => (
        <Link to={`/athletes/${athlete.id}`}>
          {screens["sm"] && <Emoji symbol={getUnicodeFlagIcon(athlete.countryCode)} />}{" "}
          {personToFullname(athlete)}
        </Link>
      ),
    },
    {
      title: "Hardest Grade",
      dataIndex: "hardestGrade",
      render: (grade) => <SportGrade frenchGrade={grade} />,
      sorter: (a, b) => a.hardestGrade.localeCompare(b.hardestGrade),
      responsive: ["sm"],
    },
    {
      title: "Score",
      dataIndex: "score",
      sorter: (a, b) => a.score - b.score,
    },
  ];

  // not found ones will be 0
  const toPoints = (grade: string) => ["9b", "9b+", "9c", "9c+"].findIndex((g) => g === grade) + 1;

  const sendsPerAthlete = groupBy(sportSends, (s) => s.person.id);
  const athletes = Object.values(sendsPerAthlete).map((s) => s[0].person);

  let currentRank = 1;
  let lastScore = 0;

  let rows: CounterRow[] = athletes
    .map((athlete) => {
      const sends = sendsPerAthlete[athlete.id];
      const grades = sends.map((r) => r.route.grade);
      return {
        key: athlete.id,
        athlete: athlete,
        hardestGrade: grades.sort((g1, g2) => g2.localeCompare(g1))[0],
        score: grades.map(toPoints).reduce((acc, cur) => acc + cur),
        sends,
      };
    })
    .sort((row1, row2) => row2.score - row1.score)
    .map((row, idx) => {
      if (lastScore !== 0 && row.score < lastScore) {
        currentRank = idx + 1;
      }
      lastScore = row.score;
      return {
        ...row,
        rank: currentRank,
      };
    });

  let expandableProps: any = {
    expandedRowKeys: expandedKeys,
    expandedRowRender: (row: any) => {
      return (
        <>
          <h4>
            Routes sent by {row.athlete.firstname} {row.athlete.lastname}
          </h4>
          <RouteSendsTable sends={row.sends} tableProps={{ pagination: false }} />
        </>
      );
    },
  };
  // if (!screens["sm"]) {
  //   expandableProps = {
  //     ...expandableProps,
  //     expandIconColumnIndex: -1,
  //   };
  // } else {
  expandableProps = {
    ...expandableProps,
    expandIconColumnIndex: columns.length,
    expandIcon: ({ expanded, onExpand, record }: any) =>
      expanded ? (
        screens["sm"] ? (
          <Button onClick={(e) => onExpand(record, e)}>Hide Routes</Button>
        ) : (
          <Button onClick={(e) => onExpand(record, e)}>-</Button>
        )
      ) : screens["sm"] ? (
        <Button onClick={(e) => onExpand(record, e)}>Show Routes</Button>
      ) : (
        <Button onClick={(e) => onExpand(record, e)}>+</Button>
      ),
  };
  // }

  const handleOnRow = (row: any, rowIndex?: number) => {
    return {
      onClick: () => {
        if (expandedKeys.findIndex((key) => key === row.key) >= 0) {
          setExpandedKeys(expandedKeys.filter((key) => key !== row.key));
        } else {
          setExpandedKeys([...expandedKeys, row.key]);
        }
      },
    };
  };

  const tableProps = {
    pagination: {
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100"],
    },
    expandable: {
      ...expandableProps,
    },
    onRow: handleOnRow,
  };

  return <Table {...tableProps} columns={columns} dataSource={rows} />;
};
export default SportCounterTable;
