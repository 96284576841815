import CreateGradeProvider, { createGradeContext } from "./GradeProvider";

export enum SportGradeSystems {
  French, // eslint-disable-line
  YDS, // eslint-disable-line
}

export const SportGradeContext = createGradeContext(SportGradeSystems);

const SportGradeProvider = ({ children }: any) => {
  return CreateGradeProvider({
    children,
    gradeContext: SportGradeContext,
    localStorageKey: "preferredSportGradeSystem",
  });
};

export default SportGradeProvider;
