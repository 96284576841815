import FullnamePerson from "../components/models/FullnamePerson";
import RouteSendsTable from "../components/tables/RouteSendsTable";
import { useFetchOnePerson } from "../hooks/useFetchAPI";
import { useParams } from "react-router-dom";

const AthletePage = () => {
  let { id } = useParams();

  const { data: athlete, error, isError, isLoading } = useFetchOnePerson(id!);

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>An unknown error occured during fetching.</div>;
  }

  if (!athlete) {
    return <div>Unknown athlete.</div>;
  }

  return (
    <div>
      <h1>Athlete</h1>
      <h2>
        <FullnamePerson person={athlete} />
      </h2>
      {athlete.description && (
        <>
          <h3>Description</h3>
          <p>{athlete.description}</p>
        </>
      )}
      {/* {athlete.birthDate && <h3>Age: {computeAge(new Date(athlete.birthDate))} years old</h3>} */}
      <h3>List of all sends</h3>
      <RouteSendsTable sends={athlete.sends!.items}></RouteSendsTable>
    </div>
  );
};

export default AthletePage;
