import ReactMarkdown from "react-markdown";
import { useLocalStorage } from "usehooks-ts";

const InfoPage = () => {
  const [md] = useLocalStorage("info-page-markdown", "");

  return (
    <div>
      <ReactMarkdown>{md}</ReactMarkdown>
    </div>
  );
};

export default InfoPage;
