import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainNavigation, { AppRoute } from "./components/layout/MainNavigation";

import AdminPage from "./pages/AdminPage";
import AthletePage from "./pages/AthletePage";
import InfoPage from "./pages/InfoPage";
import NineBCounter from "./pages/NineBCounter";
import RoutePage from "./pages/RoutePage";
import SendPage from "./pages/SendPage";

const Router = () => {
  const navRoutes: Array<AppRoute> = [
    { title: "9b Counter", path: "/", element: NineBCounter },
    { title: "About Us", path: "/about-us", element: InfoPage },
  ];

  const otherRoutes: Array<AppRoute> = [
    { path: "/admin", element: AdminPage },
    { path: "/athletes/:id", element: AthletePage },
    { path: "/routes/:id", element: RoutePage },
    { path: "/sends/:id", element: SendPage },
  ];

  const allRoutes = [...navRoutes, ...otherRoutes];

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainNavigation routes={navRoutes} />}>
          {allRoutes.map((route, idx) => (
            <Route {...route.routeProps} key={idx} path={route.path} element={<route.element />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
