import { Layout, Menu } from "antd";

import Paragraph from "antd/lib/typography/Paragraph";
import SendsLineChart from "../components/viz/SendsLineChart";
import SportCounterTable from "../components/tables/SportCounterTable";
import SportGradeSelect from "../components/layout/SportGradeSelect";
import { centeredMenuStyle } from "../components/utils/utils";
import { useFetchPublicSportSends } from "../hooks/useFetchAPI";
import { useState } from "react";

const NineBCounter = () => {
  enum Years {
    "year2020" = 2020,
    "year2021",
    "year2022",
    "allTime" = -1,
  }
  const yearsToDisplay = [Years.year2020, Years.year2021, Years.year2022];
  const [year, setYear] = useState(yearsToDisplay[yearsToDisplay.length - 1]);
  const { data: sportSends, error, isError, isLoading } = useFetchPublicSportSends();

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>An unknown error occured during fetching.</div>;
  }

  let selectedSportSends = sportSends!;
  if (year !== Years.allTime) {
    selectedSportSends = selectedSportSends.filter((s) => {
      const sendDate = new Date(s.date);
      const isSelected =
        sendDate > new Date(year - 1, 11, 31) && sendDate <= new Date(year, 11, 31);
      return isSelected;
    });
  }

  return (
    <div>
      <h2>9b Counter</h2>
      <div style={{ marginBottom: 10 }}>
        Sport Grade System: <SportGradeSelect />
      </div>
      <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: "Show more" }}>
        Hints: <br />
        View sends associated with an athlete or route by clicking on his name.
        <br />
        View routes used for the scoring of an athlete by clicking on the row or the button on the
        side.
        <br />
        Change the sorting logic by clikcing on a column name.
        <br />
        View more information on your mobile by viewing the page in landscape mode.
      </Paragraph>
      <Layout style={{}}>
        <Menu
          selectedKeys={[Years[year]]}
          onClick={(e) => setYear(Years[e.key as keyof typeof Years])}
          mode="horizontal"
          style={{ borderBottom: "none", paddingBottom: 10, ...centeredMenuStyle }}
        >
          {yearsToDisplay.map((y) => (
            <Menu.Item key={Years[y]} style={{ lineHeight: "28px" }}>
              {y === -1 ? "all time" : y}
            </Menu.Item>
          ))}
        </Menu>
      </Layout>
      <div style={{ paddingBottom: 20 }}>
        <SendsLineChart sends={selectedSportSends} />
      </div>
      <SportCounterTable sportSends={selectedSportSends} />
    </div>
  );
};
export default NineBCounter;
