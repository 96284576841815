import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { groupBy, range } from "lodash";

import { SportSend } from "../../API";

type SendsLineChartProps = {
  sends: SportSend[];
};

const SendsLineChart = ({ sends }: SendsLineChartProps) => {
  const sendsPerMonth = groupBy(sends, (s) => new Date(s.date).getMonth());
  const monthIndices = range(12);
  const numberSendsPerMonth = monthIndices.map((idx) => {
    const numberOfSend = sendsPerMonth[idx];
    return {
      month: new Date(2022, idx, 1).toLocaleString("default", { month: "short" }),
      "#sends": numberOfSend ? numberOfSend.length : 0,
    };
  });

  return (
    <div style={{ height: 250, width: "100%" }}>
      <ResponsiveContainer>
        <LineChart data={numberSendsPerMonth}>
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="month" />
          <YAxis
            orientation="left"
            label={
              <Text x={0} y={0} dx={30} dy={140} offset={0} angle={-90}>
                #sends
              </Text>
            }
          />
          <Tooltip />
          <Line type="monotone" dataKey="#sends" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SendsLineChart;
