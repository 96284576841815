import {
  getAllPeople,
  getAllSportRoutes,
  getAllSportSends,
  getOnePerson,
  getOneSportRoute,
  getOneSportSend,
} from "../api/get";

import { useQuery } from "react-query";

const SECOND = 1000;
const MINUTE = 60 * SECOND;

const DEFAULT_STALE_TIME = 5 * MINUTE;

export const useFetchPeople = () =>
  useQuery("fetchPeopleData", getAllPeople, { staleTime: DEFAULT_STALE_TIME });

export const useFetchSportRoutes = () =>
  useQuery("fetchSportRoutesData", getAllSportRoutes, { staleTime: DEFAULT_STALE_TIME });

export const useFetchPublicSportSends = () =>
  useQuery("fetchPublicSportSends", getAllSportSends, { staleTime: DEFAULT_STALE_TIME });

export const useFetchOnePerson = (id: string) =>
  useQuery(`fetchPerson-${id}`, () => getOnePerson(id), { staleTime: DEFAULT_STALE_TIME });

export const useFetchOneSportRoute = (id: string) =>
  useQuery(`fetchSportRoute-${id}`, () => getOneSportRoute(id), { staleTime: DEFAULT_STALE_TIME });

export const useFetchOneSportSend = (id: string) =>
  useQuery(`fetchSportSend-${id}`, () => getOneSportSend(id), { staleTime: DEFAULT_STALE_TIME });
