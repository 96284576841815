import { AutoComplete, Button, DatePicker, Form, FormInstance, Input } from "antd";

import { getCodeList } from "country-list";
import { invertObjKeyValue } from "../utils/utils";
import { postPerson } from "../../api/post";
import { useEffect } from "react";

const resetForm = (form: FormInstance) => {
  form.resetFields();
};

const countryNames = invertObjKeyValue(getCodeList());

const AddAthleteForm = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    resetForm(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    console.log("onFinish", form.getFieldsValue());
    postPerson({
      firstname: form.getFieldValue("firstname"),
      lastname: form.getFieldValue("lastname"),
      countryCode: countryNames[form.getFieldValue("country")].toUpperCase(),
      birthDate: form.getFieldValue("birthDate").format("YYYY-MM-DD"),
      description: form.getFieldValue("description"),
    }).then(() => {
      resetForm(form);
    });
  };

  const onReset = () => {
    resetForm(form);
  };

  const onFinishFailed = () => {
    console.log("onFinishFailed");
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item name="firstname" label="Firstname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastname" label="Lastname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Nationality"
          name="country"
          rules={[
            {
              required: true,
              type: "enum",
              enum: Object.keys(countryNames),
              message: "Unknown country",
            },
          ]}
        >
          <AutoComplete
            // style={{ width: 100 }}
            options={Object.keys(countryNames).map((c) => ({ value: c }))}
            placeholder="Nationality"
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item label="Birthdate" name="birthDate">
          <DatePicker />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <span style={{ paddingRight: 10 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </span>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddAthleteForm;
