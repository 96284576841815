import { Col, Layout, Menu, Row } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";

import { centeredMenuStyle } from "../utils/utils";

const { Header, Content, Footer } = Layout;

export type AppRoute = {
  title?: string;
  path: string;
  element: (x: any) => JSX.Element;
  routeProps?: object;
};

type MainNavProps = {
  routes: Array<AppRoute>;
};

const MainNavigation = ({ routes }: MainNavProps) => {
  const location = useLocation();

  return (
    <Layout className="layout">
      <Header>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[location.pathname]}
          style={centeredMenuStyle}
        >
          {routes.map((route) => (
            <Menu.Item key={route.path}>
              <Link to={route.path}>{route.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Header>

      <Content>
        <div className="site-layout-content">
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 16, offset: 4 }}
              xl={{ span: 16, offset: 4 }}
              xxl={{ span: 12, offset: 6 }}
            >
              <Outlet />
            </Col>
          </Row>
        </div>
      </Content>

      <Footer style={{ textAlign: "center" }}>
        Contact us at <a href="mailto:mail@9b-counter.com">mail@9b-counter.com</a>
      </Footer>
    </Layout>
  );
};

export default MainNavigation;
