/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      countryCode
      firstname
      lastname
      birthDate
      description
      sends {
        items {
          id
          route {
            id
            name
            grade
            description
            createdAt
            updatedAt
          }
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPeople = /* GraphQL */ `
  query ListPeople($filter: ModelPersonFilterInput, $limit: Int, $nextToken: String) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        countryCode
        firstname
        lastname
        birthDate
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSportRoute = /* GraphQL */ `
  query GetSportRoute($id: ID!) {
    getSportRoute(id: $id) {
      id
      name
      grade
      description
      sends {
        items {
          id
          person {
            id
            countryCode
            firstname
            lastname
            birthDate
            description
            createdAt
            updatedAt
          }
          routeID
          personID
          type
          date
          fa
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSportRoutes = /* GraphQL */ `
  query ListSportRoutes($filter: ModelSportRouteFilterInput, $limit: Int, $nextToken: String) {
    listSportRoutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        grade
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSportSend = /* GraphQL */ `
  query GetSportSend($id: ID!) {
    getSportSend(id: $id) {
      id
      routeID
      personID
      type
      date
      fa
      description
      route {
        id
        name
        grade
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      person {
        id
        countryCode
        firstname
        lastname
        birthDate
        description
        sends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSportSends = /* GraphQL */ `
  query ListSportSends($filter: ModelSportSendFilterInput, $limit: Int, $nextToken: String) {
    listSportSends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        routeID
        personID
        type
        date
        fa
        description
        route {
          id
          name
          grade
          description
          createdAt
          updatedAt
        }
        person {
          id
          countryCode
          firstname
          lastname
          birthDate
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
