import { API, Auth } from "aws-amplify";
import { Person, SportRoute, SportSend } from "../API";
import {
  getPerson,
  getSportRoute,
  getSportSend,
  listPeople,
  listSportRoutes,
  listSportSends,
} from "../graphql/queries";

import { GraphQLOptions } from "@aws-amplify/api-graphql";

interface GetListVariables {
  nextToken?: string | null;
  limit: number;
}
interface GetListOutput<Obj> {
  data: {
    [key: string]: {
      items: Obj[];
      nextToken?: string;
    };
  };
}

interface GetOneVariables {
  id: string;
}

interface GetOneOutput<Obj> {
  data: {
    [key: string]: Obj;
  };
}

interface QueryObject {
  [key: string]: string;
}

function createGetWithToken<GetOutput>(query: QueryObject) {
  return async (getVariables: any) => {
    console.log("fetching data...");
    const authenticated = await Auth.currentCredentials()
      .then((cred) => cred.authenticated)
      .catch(() => false);
    const key = Object.keys(query)[0];
    let options: GraphQLOptions = {
      query: query[key],
      variables: getVariables,
    };
    if (authenticated) {
      options.authMode = "AMAZON_COGNITO_USER_POOLS";
    }
    return API.graphql(options) as Promise<GetOutput>;
  };
}

async function getAll<Obj>(listQuery: QueryObject, getVariables: GetListVariables) {
  const getListAndToken = createGetWithToken<GetListOutput<Obj>>(listQuery);
  const key = Object.keys(listQuery)[0];
  let allList: Obj[] = [];
  let hasRun = false;
  while (!hasRun || getVariables.nextToken) {
    const {
      data: {
        [key]: { nextToken, items },
      },
    } = await getListAndToken(getVariables);
    hasRun = true;
    getVariables.nextToken = nextToken;
    allList = allList.concat(items);
  }
  return allList;
}

async function getOne<Obj>(oneQuery: QueryObject, getVariables: GetOneVariables) {
  const getOneObj = createGetWithToken<GetOneOutput<Obj>>(oneQuery);
  const key = Object.keys(oneQuery)[0];
  const {
    data: { [key]: oneObj },
  } = await getOneObj(getVariables);
  return oneObj;
}

export const getAllPeople: () => Promise<Person[]> = async () => {
  const people = await getAll<Person>({ listPeople }, { limit: 2000 });
  return people.sort((a, b) => {
    const aName = `${a.firstname} ${a.lastname}`;
    const bName = `${b.firstname} ${b.lastname}`;
    return aName.localeCompare(bName);
  });
};

export const getAllSportRoutes: () => Promise<SportRoute[]> = async () => {
  const sportRoutes = await getAll<SportRoute>({ listSportRoutes }, { limit: 2000 });
  return sportRoutes.sort((a, b) => a.name.localeCompare(b.name));
};

export const getAllSportSends: () => Promise<SportSend[]> = async () => {
  const sportRoutes = await getAll<SportSend>({ listSportSends }, { limit: 2000 });
  return sportRoutes.sort((a, b) => b.date.localeCompare(a.date));
};

export const getOnePerson: (id: string) => Promise<Person> = async (id) => {
  return await getOne<Person>({ getPerson }, { id: id });
};

export const getOneSportRoute: (id: string) => Promise<SportRoute> = async (id) => {
  return await getOne<SportRoute>({ getSportRoute }, { id: id });
};

export const getOneSportSend: (id: string) => Promise<SportSend> = async (id: string) => {
  return await getOne<SportSend>({ getSportSend }, { id: id });
};
